import { initializeApp } from "firebase/app"
import {
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
} from "firebase/auth"
import { flashMessage, getCsrfToken, notificationFlash } from "./_utility"

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_PROJECT_ID + ".firebaseapp.com",
  databaseURL: "https://" + process.env.FIREBASE_PROJECT_ID + ".firebaseio.com",
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_PROJECT_ID + ".appspot.com",
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
}
// Initialize Firebase
initializeApp(firebaseConfig)
let auth = getAuth()
document
  .getElementById("js-firebase__login")
  ?.addEventListener("click", function (e) {
    e.preventDefault()
    let email = document.getElementById("user_email")?.value
    let password = document.getElementById("user_password")?.value
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        res.user.getIdToken().then((idToken) => {
          return railsLogin(idToken)
        })
      })
      .catch((_error) => {
        document.getElementById("user_password").value = ""
        notificationFlash({
          message: "メールアドレスまたはパスワードが正しくありません。",
          type: "alert",
        })
        return false
      })
  })

const railsLogin = (idToken) => {
  const xmlhttp = new XMLHttpRequest()
  xmlhttp.open("POST", "/user_sessions", true)
  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
      let json
      try {
        json = JSON.parse(xmlhttp.responseText)
      } catch (e) {
        json = {}
      }
      window.location.replace(json.return_to || "/")
    } else if (xmlhttp.readyState === 4 && xmlhttp.status === 422) {
      window.location.href = `/login/#js-flash--alert__invalid_token`
    }
  }
  xmlhttp.setRequestHeader("X-CSRF-Token", getCsrfToken())
  xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded")
  xmlhttp.setRequestHeader("Authorization", `Bearer ${idToken}`)
  xmlhttp.send(null)
}

const updateEmailVerified = (email, handler) => {
  if (!email) {
    handler(false)
  }

  const formData = new FormData()
  formData.append("email", email)

  const xmlhttp = new XMLHttpRequest()
  xmlhttp.responseType = "json"
  xmlhttp.open("POST", "/users/verify_email", true)
  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState === XMLHttpRequest.DONE) {
      typeof handler === "function" &&
        handler(xmlhttp.status === 200, xmlhttp.response)
    }
  }
  xmlhttp.setRequestHeader("X-CSRF-Token", getCsrfToken())
  xmlhttp.send(formData)
}

document
  .getElementById("js-firebase__password--forget")
  ?.addEventListener("click", function (e) {
    let email = document.getElementById("user_email")?.value
    e.preventDefault()
    sendPasswordResetEmail(auth, email)
      .then(function () {
        document.getElementById("user_email").value = ""
        notificationFlash({
          message: flashMessage("notice__passwordsend"),
          type: "notice",
        })
      })
      .catch((error) => {
        notificationFlash({
          message: flashMessage("alelrt__invalidemail"),
          type: "alert",
        })
      })
  })

document
  .getElementById("js-firebase__signup")
  ?.addEventListener("click", (e) => {
    e.preventDefault()
    e.target.disabled = true
    let target = e.target.closest("form")
    let action = target.action
    let returnurl = target.getAttribute("data-next")
    let data = new FormData(target)
    const xmlhttp = new XMLHttpRequest()
    xmlhttp.open("POST", action, true)
    xmlhttp.onreadystatechange = () => {
      switch (xmlhttp.readyState) {
        case 4:
          if (xmlhttp.status === 200) {
            gtag({
              event: "sign_up",
              method: "google",
            })
            window.location.href = `${returnurl}`
          } else {
            try {
              const json = JSON.parse(xmlhttp.response)
              clearRailsError()
              showErrorFromRails(json, target)
            } catch (e) {
              window.location.href = `/#js-flash--alert__signup`
            }
          }
          break
        default:
      }
    }
    xmlhttp.setRequestHeader("X-CSRF-Token", getCsrfToken())
    xmlhttp.send(data)
  })

document
  .getElementById("js-firebase__resend")
  ?.addEventListener("click", (e) => {
    e.preventDefault()
    let email = document.getElementById("user_email")?.value
    let password = document.getElementById("user_password")?.value
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        res.user
          .sendEmailVerification()
          .then(() => {
            window.location.href = `/#js-flash--notice__notsentverifyemail`
          })
          .catch((_error) => {
            window.location.href = `/#js-flash--alert__notsentverifyemail`
          })
      })
      .catch((_error) => {
        document.getElementById("user_password").value = ""
        notificationFlash({
          message: "メールアドレスまたはパスワードが正しくありません。",
          type: "alert",
        })
        return false
      })
  })

document.addEventListener(
  "DOMContentLoaded",
  () => {
    let mode = getParameterByName("mode")
    let actionCode = getParameterByName("oobCode")
    let continueUrl = getParameterByName("continueUrl")
    let lang = getParameterByName("lang") || "ja"
    switch (mode) {
      case "resetPassword":
        handleResetPassword(auth, actionCode, continueUrl, lang)
        break
      case "recoverEmail":
        handleRecoverEmail(auth, actionCode, lang)
        break
      case "verifyEmail":
        handleVerifyEmail(auth, actionCode, continueUrl, lang)
        break
      default:
    }
  },
  false
)

const handleVerifyEmail = (auth, actionCode, _continueUrl, _lang) => {
  const userSignInElm = document.getElementById("js_is_user_sign_in")
  const isUserSignIn = JSON.parse(userSignInElm.dataset.value).is_user_sign_in
  let email = null
  checkActionCode(auth, actionCode)
    .then((info) => {
      email = info?.data?.email
      return applyActionCode(auth, actionCode)
    })
    .then(() => {
      updateEmailVerified(email, (result, response) => {
        if (typeof response !== "object") {
          window.location.replace("/")
          return
        }

        switch (response.career_status) {
          case "student":
            gtag({
              event: "sign_up",
              method: "student_email",
            })
            window.location.href = "/student_welcome"
            break
          default:
            if (isUserSignIn && !response.permit) {
              if (response.initial_certification) {
                gtag({
                  event: "sign_up",
                  method: "email",
                })
                window.location.href = "/welcome"
              } else {
                window.location.href =
                  "/step/selection#js-flash--notice__verify"
              }
            } else {
              window.location.href = "/#js-flash--notice__verify"
            }
        }
      })
    })
    .catch((_error) => {
      window.location.href = "/#js-flash--notice__alreadysignin"
    })
}

const handleResetPassword = (auth, actionCode, _continueUrl, _lang) => {
  verifyPasswordResetCode(auth, actionCode)
    .then((_email) => {
      document
        .getElementById("js-firebase__password--reset")
        ?.addEventListener("click", function (e) {
          let password = document.getElementById("user_password")?.value
          e.preventDefault()
          confirmPasswordReset(auth, actionCode, password)
            .then(function (_resp) {
              window.location.href = `/login#js-flash--notice__passwordchange`
            })
            .catch((error) => {
              let errorCode = error.code
              switch (errorCode) {
                case "auth/expired-action-code":
                  window.location.href = `/password/reset#js-flash--alert__expired`
                case "auth/weak-password":
                  notificationFlash({
                    message: flashMessage("alert__weakpassword"),
                    type: "alert",
                  })
                  break
                default:
              }
            })
        })
    })
    .catch((error) => {
      let errorCode = error.code
      switch (errorCode) {
        case "auth/expired-action-code":
          window.location.href = `/password/reset#js-flash--alert__expired`
        default:
          window.location.href = `/password/reset#js-flash--alert__invalid`
      }
    })
}

const getParameterByName = (name, url) => {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, "\\$&")
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ""
  return decodeURIComponent(results[2].replace(/\+/g, " "))
}
